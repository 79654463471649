import React, { useState, useEffect, Suspense, lazy } from 'react';
import LoadingSpinner from './components/LoadingSpinner';
import './App.scss';
import './App-mobile.scss';
import logo from './img/logo.png';

const Header = lazy(() => import('./components/Header'));
const ConnectingDots = lazy(() => import('./components/ConnectingDots'));
const Über = lazy(() => import('./components/Über'));
const Footer = lazy(() => import('./components/ui/Footer'));
const Sec3 = lazy(() => import('./components/sec3'));

const ArrowDown = ({ onClick, className }) => (
  <div className={className} onClick={onClick}></div>
);

const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

function App() {
  const [loading, setLoading] = useState(true);
  const [showHeader, setShowHeader] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [originalTitle, setOriginalTitle] = useState(document.title);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const section1 = document.querySelector('.section-1');
      const section2 = document.querySelector('.section-2');
      const img = document.querySelector('.section-1 img');
      const text = document.querySelector('.apple-style-text');

      if (!section1 || !section2 || !img || !text) {
        return;
      }

      const sectionHeight = section1.clientHeight;
      const scrollY = window.scrollY;
      const imgOpacity = 1 - scrollY / sectionHeight;
      const textOpacity = 1 - (scrollY / sectionHeight) * 2;

      requestAnimationFrame(() => {
        img.style.opacity = imgOpacity;
        text.style.opacity = textOpacity;
      });

      if (scrollY >= section2.offsetTop) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      return; // Disable resize listener for mobile devices
    }

    const handleResize = debounce(() => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      const widthChange = Math.abs(newWidth - windowSize.width);
      const heightChange = Math.abs(newHeight - windowSize.height);
      const threshold = 50;

      if (widthChange > threshold || heightChange > threshold) {
        setWindowSize({ width: newWidth, height: newHeight });
        localStorage.setItem('scrollPosition', window.scrollY);
        window.location.reload();
      }
    }, 100);

    const restoreScrollPosition = () => {
      const savedPosition = localStorage.getItem('scrollPosition');
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition, 10));
        localStorage.removeItem('scrollPosition');
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', restoreScrollPosition);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', restoreScrollPosition);
    };
  }, [windowSize]);

  useEffect(() => {
    let blinkInterval;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        let showOriginalTitle = true;
        blinkInterval = setInterval(() => {
          document.title = showOriginalTitle ? '🙌 Ihre Website wartet 🙌' : originalTitle;
          showOriginalTitle = !showOriginalTitle;
        }, 1000); // Change the interval as needed
      } else {
        clearInterval(blinkInterval);
        document.title = originalTitle;
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(blinkInterval);
    };
  }, [originalTitle]);

  const scrollToSectionTwo = () => {
    const section2 = document.querySelector('.section-2');
    section2.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToSectionThree = () => {
    const section3 = document.querySelector('.section-3');
    section3.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Suspense fallback={<LoadingSpinner />}>
          <section className="section-1" style={{ paddingTop: '60px' }}>
            <ConnectingDots />
            <img src={logo} alt="Logo" loading="lazy" />
            <div className="apple-style-text">
              Eine beeindruckende Website: Ihr Schlüssel zu mehr{' '}
              <span className="highlight">Sichtbarkeit</span> und{' '}
              <span className="highlight">Vertrauen</span>.
            </div>
            <ArrowDown className="arrow-down" onClick={scrollToSectionTwo} />
          </section>
          <section className="section-2">
            <Über />
          </section>
          <section className="section-3">
            <Sec3 />
          </section>
          <Footer />
        </Suspense>
      )}
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import './LoadingSpinner.scss';

const LoadingSpinner = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000); // Show spinner for 2 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`loading ${!visible ? 'fade-out' : ''}`}>
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
